export enum BusEvent {
	OPEN_NAV_MENU = 'open-nav-menu',
	OPEN_TOUR_MENU = 'open-tour-menu',
	OPEN_APP_MENU = 'open-app-menu',
	PIN_REQUEST = 'pin-request',
	PIN_DISMISS = 'pin-dismiss',
	WAIVER_EVENT = 'waiver-event',
	OPEN_IN_DAY_VIEW = 'open-in-day-view',
	DISABLE_SEARCH = 'disable-search',
	ENABLE_SEARCH = 'enable-search',
	TOGGLE_ORDER_MENU = 'open-checkout-menu',
	SEARCH_REQUEST = 'search-request',
	ALERT_REQUEST = 'alert-request',
}
